import { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import onBrowser from "utils/onBrowser"

const useRedirect = url => {
  const {
    site: {
      siteMetadata: { organizationHomePage: defaultRedirect },
    },
  } = useStaticQuery(graphql`
    query siteUrlQuery {
      site {
        siteMetadata {
          organizationHomePage
        }
      }
    }
  `)

  useEffect(() => {
    if (onBrowser) {
      window.location.replace(url || defaultRedirect)
    }
  }, [])

  return null
}

export default useRedirect
